import React, { useState } from "react";
import "./style.scss";
import QuestionsTabs from "../../Questions/QuestionsTabs";
import { faqQuestions } from "../../../data";
import { PopupHeader } from "../index";

const Faq = ({ setVal }) => {
  const [, setTabActive] = useState(0);
  const getIndex = (index) => {
    setTabActive(index);
  };
  return (
    <div className="faq">
      <PopupHeader caption={"FAQ"} />
      <div className="page-container">
        <div className="popup__container">
          <QuestionsTabs questions={faqQuestions} getIndex={getIndex} />
          <div className="text-center">
            <a
              onClick={() => setVal("")}
              className="btn btn_white btn_contact-us"
              href="#footer"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
