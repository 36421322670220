import React, {useEffect} from "react";
import Layout from "../Home/Layout";
import Svg from "../components/Svg";

export const setVhSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const InitialConfigProvider = ({children}) => {
    useEffect(() => {
        setVhSize();

        window.addEventListener('resize', setVhSize);
        return () => {
            window.removeEventListener('resize', setVhSize);
        };
    }, [])

    return (
        <>
            <Svg/>
            <Layout>
                {children}
            </Layout>
        </>
    )
}


export default InitialConfigProvider;