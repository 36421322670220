import cn from "classnames";
import { useRef } from "react";
import { useState } from "react";
import poll from "../../../api";
import { POLL, polls } from "../../../data";
import styles from "./index.module.scss";
import { ReactComponent as ShareIconSvg } from "../../../assets/svg(optional)/share.svg";

const Poll = ({ open, setIsOpen }) => {
  const [activeId, setActiveId] = useState(0);
  const [isVoteDone, setIsVoteDone] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const shareRef = useRef(null);

  const sendVote = async () => {
    try {
      await poll.createVote({
        poll_id: POLL.id,
        option_id: activeId,
        identifier: POLL.identifier,
      });
      setIsVoteDone(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setIsVoteDone(false);
    setIsOpen(false);
  };

  return (
    <div className={cn(styles["poll"], { [styles["poll_show"]]: open })}>
      <div className="relative">
        <button onClick={handleClose} className={styles["poll__close"]}>
          <span className="img-container">
            <svg className="icon">
              <use xlinkHref="#close" />
            </svg>
          </span>
        </button>
        <div
          className={cn(styles["poll__done"], {
            [styles["poll__done_show"]]: isVoteDone,
          })}
        >
          <div>
            <h2>Thank You!</h2>
            <div className={styles["share"]}>
              <div
                ref={shareRef}
                className={cn(styles["share-btns"], {
                  [styles["share-btns_active"]]: isShare,
                })}
              >
                <button
                  className={styles["share-close"]}
                  onClick={() => setIsShare(false)}
                >
                  <div>X</div>
                </button>
                <div className="sharethis-inline-share-buttons"></div>
              </div>
              <button
                onClick={() => setIsShare(true)}
                className={styles["share__btn"]}
                href="https://www.facebook.com/sharer/sharer.php?u=https://depressedcitizens.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ShareIconSvg />
              </button>
            </div>
            <p>Share and shill your mint on social media {">"}</p>
          </div>
        </div>
        {!isVoteDone && (
          <>
            <h2 className={styles["poll__title"]}>Benefits</h2>
            <p>
              <strong>Congratulations on your mints!</strong>{" "}
            </p>
            <p>
              Did you know that if you hold 5 DC NFTs you would be eligible for
              a custom DC NFT airdrop? You may mint additional NFTs during the
              Public Mint starting on Oct 1st at 6 pm UTC.
            </p>
            <p>
              We will also be allocating 10% of our funds to 1 of 3 social
              causes. Be part of the change and help us pick one:{" "}
            </p>
            <div className={styles["poll__wrap"]}>
              {polls.map(({ id, img, title, text }) => (
                <div className={styles["poll__block"]} key={id}>
                  <button
                    onClick={() => setActiveId(id)}
                    className={cn(styles["poll__block__head"], {
                      [styles["poll__block__head_active"]]: activeId === id,
                    })}
                  >
                    <img src={img} alt={title} />
                    <h3>{title}</h3>
                  </button>
                  <p>{text}</p>
                </div>
              ))}
            </div>
            <p className={styles["poll__free"]}>
              *This is free. No additional cost.
            </p>
            <div className={styles["poll__btns-wrap"]}>
              <div></div>
              <button onClick={sendVote} className={styles["poll__btn"]}>
                Submit Vote
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className={styles["poll__skip"]}
              >
                Skip {">"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Poll;
