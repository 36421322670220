import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { DAppProvider } from "./context/dapp";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <DAppProvider>
    <App />
  </DAppProvider>
);
