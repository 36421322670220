import styles from "./index.module.scss";
import powerImg from "../../assets/img/contribution/power.png";
import crownImg from "../../assets/img/contribution/crown.png";
import smileImg from "../../assets/img/contribution/smile.png";

const Contribution = () => {
  return (
    <section className={styles["contribution"]}>
      <div className="page-container">
        <h2 className={styles["contribution__title"]}>ART PROJECT</h2>
        <p className={styles["contribution__quote"]}>
          This is only ART. 
          No team, no project, no future. 
          100% hand made collection. 
        </p>
        <p className={styles["contribution__desc"]}>
          But we believe that for a better society we must all have access to :
        </p>
        <div className={styles["contribution__btns"]}>
          <a
            className="btn btn_white"
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/cSWj6mJTHr"
          >
            Join the Rebellion{" "}
            <span className={styles["contribution__icon"]}>
              <span className="img-container">
                <svg className="icon">
                  <use xlinkHref="#discord" />
                </svg>
              </span>
            </span>
          </a>
          <a className="btn btn_gray" href="#pdf">
            DOWNLOAD PDF{" "}
            <span className={styles["contribution__icon"]}>
              <span className="img-container">
                <svg className="icon">
                  <use xlinkHref="#arrow-bottom" />
                </svg>
              </span>
            </span>
          </a>
        </div>
        <div className={styles["contribution__wrap"]}>
          <div className={styles["contribution__block"]}>
            <div className={styles["contribution__block-img-wrap"]}>
              <img src={powerImg} alt="Power" />
            </div>
            <h3 className={styles["contribution__block-title"]}>
              {" "}
              Access to EDUCATION
            </h3>
          </div>
          <div className={styles["contribution__block"]}>
            <div className={styles["contribution__block-img-wrap"]}>
              <img src={crownImg} alt="Power" />
            </div>
            <h3 className={styles["contribution__block-title"]}>
              Access to Technology
            </h3>
          </div>
          <div className={styles["contribution__block"]}>
            <div className={styles["contribution__block-img-wrap"]}>
              <img src={smileImg} alt="Power" />
            </div>
            <h3 className={styles["contribution__block-title"]}>
              Access to Mental Health
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contribution;
