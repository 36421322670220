import React, { useEffect, useState } from "react";
import InitialConfigProvider from "./util/InitialConfigProvider";
import Home from "./Home";
import { PopupContext } from "./context/popup";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PollPage from "./Poll";

const App = () => {
  const [val, setVal] = useState("");
  useEffect(() => {
    if (val !== "") {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "hidden";
    } else {
      document.body.style.overflow = null;
      document.body.style.touchAction = null;
    }
  }, [val]);

  return (
    <BrowserRouter>
      <PopupContext.Provider value={[val, setVal]}>
        <InitialConfigProvider>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/poll" element={<PollPage />} />
          </Routes>
          <Toaster position="center-bottom" />
        </InitialConfigProvider>
      </PopupContext.Provider>
    </BrowserRouter>
  );
};

export default App;
