import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import {
  borderTopSec,
  chapterOneSlide1,
  ChapterStory1,
  ChapterStory2,
  chevron,
  chevronl,
  chvideo,
  story2l,
  story2r,
  story3l,
  story3r,
} from "../../assets";
import { inView } from "../../util/helper";
import styles from "./index.module.scss";

const ChapterOne = () => {
  const [animate, setAnimate] = useState(false);
  const [activeId, setActiveId] = useState(1);
  const chapterRef = useRef();
  const chapterScroll = () => {
    setAnimate(inView(chapterRef));
  };

  const handlePrev = () => {
    setActiveId((prev) => (prev === 1 ? 3 : prev - 1));
  };

  const handleNext = () => {
    setActiveId((prev) => (prev === 3 ? 1 : prev + 1));
  };

  useEffect(() => {
    window.addEventListener("scroll", chapterScroll);
    if (animate === true) window.removeEventListener("scroll", chapterScroll);
  }, [animate]);

  return (
    <section id="story" ref={chapterRef} className={styles["chapter"]}>
      <div className="page-container">
        <div className={styles["slide"]}>
          <div>
            <img
              className={styles["slide__bg"]}
              src={chapterOneSlide1}
              alt="Chapter One"
            />
            <div className={styles["slide__btns"]}>
              <button
                className={`${styles["slide__btn"]}`}
                onClick={handlePrev}
              >
                <img className="chevron-l" src={chevronl} alt="Chevron" />
              </button>
              <button
                className={`${styles["slide__btn"]}`}
                onClick={handleNext}
              >
                Next <img className="chevron-r" src={chevron} alt="Chevron" />
              </button>
            </div>
          </div>
          <>
            {activeId === 1 && (
              <div className="relative">
                <img
                  className={cn(
                    styles["slide__story-two"],
                    styles[`slide__story-two_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={ChapterStory2}
                  alt="Story"
                />
                <img
                  className={cn(
                    styles["slide__story-one"],
                    styles[`slide__story-one_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={ChapterStory1}
                  alt="Story"
                />
              </div>
            )}
            {activeId === 2 && (
              <div className="relative">
                <img
                  className={cn(
                    styles["slide__story-one"],
                    styles[`slide__story-one_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={story2l}
                  alt="Story"
                />
                <img
                  className={cn(
                    styles["slide__story-two"],
                    styles[`slide__story-two_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={story2r}
                  alt="Story"
                />
              </div>
            )}
            {activeId === 3 && (
              <div className="relative">
                <img
                  className={cn(
                    styles["slide__story-one"],
                    styles[`slide__story-one_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={story3l}
                  alt="Story"
                />
                <img
                  className={cn(
                    styles["slide__story-two"],
                    styles[`slide__story-two_${activeId}`],
                    {
                      [styles[`slide__story-two_${activeId}_animate`]]: animate,
                    }
                  )}
                  src={story3r}
                  alt="Story"
                />
              </div>
            )}
          </>
          {activeId === 1 && (
            <p className={styles["slide__desc"]}>
              In our possession is The only weapon we have. Runes-555 is our
              hope to revert our people back from their dormant state. The
              sleepers are resisting but one by one, we build our alliance, We
              fight for our freedom!
            </p>
          )}
          {activeId === 2 && (
            <p className={styles["slide__desc"]}>
              Since I can remember, Society is controled by an entity named THEI
              (the happy end inc.) THEI would manipulate us through our favorite
              entertainment, Media, news, inside our own home. Thei are
              everywhere, Thei own the world, Thei own us.
            </p>
          )}

          {activeId === 3 && (
            <p className={styles["slide__desc"]}>
              Thei force Its citizens into an emotionless routine of hard work
              and financial pursuit for their own selfish interests with false
              promises of prosperity, via a manipulation strategy called
              “Dreams”. The affected are named “Sleepers”.
            </p>
          )}
        </div>
        <div className={styles["hybrids"]}>
          <img src={chvideo} alt="Hybrid" />
        </div>
        <div className={styles["info"]}>
          <h2 className={styles["info__title"]}>awaken SLEEPERS TO HYBRIDS</h2>
          <p className={styles["info__desc"]}>
            To mint a rare Hybrid Citizen you must possess a pair of Depressed &
            Degen Citizens in your wallet and the Runes-555 NFT. Hybrid
            Citizens are special. They come with unique utilities and rewards.
          </p>
          <div className={styles["info__btns"]}>
            <a className="btn btn_white" href="#story">
              Read Story{" "}
              <svg className="icon">
                <use xlinkHref="#arrow-top" />
              </svg>
            </a>
            <a
              className="btn btn_gray"
              href="https://www.instagram.com/depressedcitizens/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow on IG
              <svg className="icon">
                <use xlinkHref="#insta" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <img
        className={styles["chapter__border"]}
        src={borderTopSec}
        alt="Border"
      />
    </section>
  );
};

export default ChapterOne;
