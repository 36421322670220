import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

const QuestionsImage = ({ questions, index }) => {
  const [animate, setAnimate] = useState(false);
  const questionImage = useRef();
  useEffect(() => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 300);
  }, [index]);
  return (
    <>
      {questions[index].img ? (
        <div
          ref={questionImage}
          className={`questions__image ${
            animate ? "questions__image--animate " : ""
          }questions__image--${questions[index]?.className}`}
        >
          <img src={questions[index].img} alt={questions[index].imgAlt} />
        </div>
      ) : (
        <div
          className={`questions__answer-container${
            animate ? " questions__answer-container--animate" : ""
          }`}
        >
          {questions[index].content}
        </div>
      )}
    </>
  );
};

export default QuestionsImage;
