import React, { useContext } from "react";
import "./style.scss";
import { PopupContext } from "../../context/popup";
import Faq from "./Faq";
import Contract from "./Contract";
import Mint from "./Mint";
import WhitePaper from "../../components/whitepaper";
import Video from "./Video";

export const PopupHeader = ({ caption, popupName }) => {
  const [, setVal] = useContext(PopupContext);
  return (
    <div
      className={`popup__header ${popupName && `popup__header--${popupName}`}`}
    >
      <div className="empty" />
      <h2 className="popup__title">{caption}</h2>
      <button className="popup__close-btn" onClick={() => setVal("")}>
        <p>Close</p>
        <span>
          <svg className="icon">
            <use xlinkHref="#close" />
          </svg>
        </span>
      </button>
    </div>
  );
};

const Popup = () => {
  const [val, setVal] = useContext(PopupContext);
  return (
    <div className="popup">  
        {val === "faq" && <Faq setVal={setVal}  />}
        {val === "contract" && <Contract />}
        {val === "mint" && <Mint />}
        {val === "whitepaper" && <WhitePaper setVal={setVal} />}
        {val === "video" && <Video />}
    </div>
  );
};

export default Popup;
