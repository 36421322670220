import React, {useState} from "react";
import './style.scss';
import QuestionsTabs from "./QuestionsTabs";
import {questions} from "../../data";
import QuestionsImage from "./QuestionsImage";
import { borderBottom } from "../../assets";

const Questions = () => {
    const [index, setIndex] = useState(0);
    const getIndex = (index) => {
        setIndex(index)
    }
    return (
        <section className="questions" id='questions'>
            <div className="page-container">
                <div className="questions__container">
                    <div className="questions__image-container">
                        <QuestionsImage questions={questions} index={index}/>
                    </div>
                    <div className="questions__list-container">
                        <QuestionsTabs questions={questions} getIndex={getIndex}/>
                        <a href="#team" className="questions__list-link">
                            <span className="questions__link-text fz-19">
                                View the team
                            </span>
                            <span className="questions__link-icon">
                                <span className="img-container">
                                    <svg className="icon">
                                      <use xlinkHref="#arrow-bottom"/>
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <img className="questions__border" alt="Border" src={borderBottom} />
        </section>
    )
}

export default Questions