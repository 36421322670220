import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import styles from "./index.module.scss";
import { ReactComponent as ShareSvg } from "../../assets/svg(optional)/share.svg";
import cn from "classnames";
import { inView } from "../../util/helper";
const Share = () => {
  const [isShare, setIsShare] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const shareRef = useRef(null);
  const descRef = useRef(null);

  useEffect(() => {
    const listenOutsideClick = ({ target }) => {
      if (isShare && shareRef.current && !shareRef.current.contains(target)) {
        setIsShare(false);
      }
    };

    document.body.addEventListener("mousedown", listenOutsideClick);

    return () => {
      document.body.removeEventListener("mousedown", listenOutsideClick);
    };
  }, [isShare]);

  useEffect(() => {
    const onScroll = () => {
      setIsAnimate(inView(descRef));
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <section className={styles["share-sec"]}>
      <div className="page-container">
        <p
          ref={descRef}
          className={cn(styles["share-sec__desc"], {
            [styles["share-sec__desc_animate"]]: isAnimate,
          })}
        >
          THEI force Its citizens into an emotionless routine of hard work and
          financial pursuit for their own selfish interests with false promises
          of prosperity, via a manipulation strategy called “Dreams”. The
          affected are named “Sleepers”.
        </p>
        <div className={styles["share"]}>
          <div
            ref={shareRef}
            className={cn(styles["share-btns"], {
              [styles["share-btns_active"]]: isShare,
            })}
          >
            <button
              className={styles["share-close"]}
              onClick={() => setIsShare(false)}
            >
              <div>X</div>
            </button>
            <div className="sharethis-inline-share-buttons"></div>
          </div>
          <button
            onClick={() => setIsShare(true)}
            className={styles["share__btn"]}
            href="https://www.facebook.com/sharer/sharer.php?u=https://depressedcitizens.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ShareSvg />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Share;
