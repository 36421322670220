import { useEffect, useState } from "react";
import poll from "../api";
import Loader from "../components/loader";
import styles from "./index.module.scss";

const PollPage = () => {
  const [data, setData] = useState();
  const [sum, setSum] = useState();

  const getVotes = async () => {
    const res = await poll.getVotes();
    const sum = await res.reduce((acc, current) => {
      return acc + current.votes_count;
    }, 0);

    setData(res);
    setSum(sum);
  };

  useEffect(() => {
    getVotes();
  }, []);

  return (
    <div className={styles["poll"]}>
      <div className="page-container">
        <h1>Votes</h1>
        <div>
          {!data && <Loader />}
          {data &&
            data.map((poll) => (
              <div key={poll.id}>
                <h3>
                  {poll.text}: <span>{poll.votes_count}</span>
                </h3>
              </div>
            ))}
          <hr />
          {!!sum && <h3>Total: {sum}</h3>}
        </div>
      </div>
    </div>
  );
};

export default PollPage;
