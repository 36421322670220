import React, { useContext } from "react";
import Form from "../../../components/form";
import { PopupContext } from "../../../context/popup";
import "./style.scss";

const Footer = () => {
  const [, setVal] = useContext(PopupContext);
  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer" id="footer">
      <button className="footer__scroll-btn" onClick={scrollHandler}>
        <span className="footer__scroll-btn-container">
          <span className="img-container">
            <svg className="icon">
              <use xlinkHref="#arrow-top" />
            </svg>
          </span>
        </span>
      </button>
      <div className="footer-top">
        <div className="page-container">
          <div className="footer-top__container">
            <div className="footer__wrapper footer__wrapper--form">
              <div className="footer__block">
                <h2 className="footer__title">Contact Us</h2>
                <p className="footer__desc fz-19">Ask us anything.</p>
                <Form />
              </div>
            </div>
            <div className="footer__wrapper footer__wrapper--links">
              <div className="footer__block">
                <h2 className="footer__title">Links</h2>
                <ul className="footer__nav-list">
                  {/*<li className="footer__nav-item">*/}
                  {/*    <button className="footer__nav-link fz-19" onClick={() => setVal('contract')}>*/}
                  {/*        Verified Smart Contract Address*/}
                  {/*    </button>*/}
                  {/*</li>*/}
                  <li className="footer__nav-item">
                    <button
                      className="footer__nav-link fz-19"
                      onClick={() => setVal("faq")}
                    >
                      FAQs
                    </button>
                  </li>
                  <li className="footer__nav-item">
                    <a href="#about" className="footer__nav-link fz-19">
                      About Us
                    </a>
                  </li>
                  <li className="footer__nav-item">
                    <a href="#team" className="footer__nav-link fz-19">
                      Team
                    </a>
                  </li>
                  <li className="footer__nav-item">
                    <a
                      onClick={() => setVal("whitepaper")}
                      href="#whitepaper"
                      className="footer__nav-link fz-19"
                    >
                      {" "}
                      Whitepaper
                    </a>
                  </li>
                  <li className="footer__nav-item">
                    <a
                      onClick={() => setVal("whitepaper")}
                      href="#terms"
                      className="footer__nav-link fz-19"
                    >
                      {" "}
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="footer__nav-item">
                    <a
                      onClick={() => setVal("whitepaper")}
                      href="#ownership"
                      className="footer__nav-link fz-19"
                    >
                      {" "}
                      Ownership Rights
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__block">
                <h2 className="footer__title">Follow Us On Social Media</h2>
                <ul className="footer__icons-list">
                  <li className="footer__icons-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://discord.gg/cSWj6mJTHr"
                      className="footer__icon footer__icon--discord"
                    >
                      <span className="footer__icon-container footer__icon-container--discord">
                        <span className="img-container">
                          <svg className="icon">
                            <use xlinkHref="#discord" />
                          </svg>
                        </span>
                      </span>
                    </a>
                  </li>
                  <li className="footer__icons-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/DepressCitizens"
                      className="footer__icon"
                    >
                      <span className="footer__icon-container footer__icon-container--twitter">
                        <span className="img-container">
                          <svg className="icon">
                            <use xlinkHref="#twitter" />
                          </svg>
                        </span>
                      </span>
                    </a>
                  </li>
                  <li className="footer__icons-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/depressedcitizens/"
                      className="footer__icon"
                    >
                      <span className="footer__icon-container footer__icon-container--insta">
                        <span className="img-container">
                          <svg className="icon">
                            <use xlinkHref="#insta" />
                          </svg>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="page-container">
          <div className="footer-bottom__container">
            <p className="footer-bottom__text fz-19">
              Copyright © All rights reserved <b>Depressed Citizens 2022</b>
            </p>
            <p className="footer-bottom__text fz-19">
              Designed by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.behance.net/tinomendes"
              >
                Tino Mendes
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
