import React from "react";
import poll1 from "../src/assets/img/poll/1.png";
import poll2 from "../src/assets/img/poll/2.png";
import poll3 from "../src/assets/img/poll/3.png";
import rarity1 from "../src/assets/img/rarity/1.png";
import rarity2 from "../src/assets/img/rarity/2.png";
import rarity3 from "../src/assets/img/rarity/3.png";
import rarity4 from "../src/assets/img/rarity/4.png";
import rarity5 from "../src/assets/img/rarity/5.png";
import rarity6 from "../src/assets/img/rarity/6.png";
import rarity7 from "../src/assets/img/rarity/7.png";
import rarity8 from "../src/assets/img/rarity/8.png";
import {
  chHybrid,
  chMainGuy,
  chReveal,
  collection1,
  collection2,
  collection3,
  collection4,
  collection5,
  collection6,
  discord,
  instagram,
  questionAbout,
  questionDissatisfied,
  tiktok,
} from "./assets";
import iphone from "./assets/img/questions/iphone12.png";
import spotifyLogo from "./assets/img/questions/spotify-logo.png";
import spotify from "./assets/img/questions/spotify.png";
import ben from "./assets/img/team/teamBen.jpg";
import eric from "./assets/img/team/teamEric.png";
import juls from "./assets/img/team/teamJuli.jpg";
import tino from "./assets/img/team/teamTino.jpg";

export const navList = [
  { text: "About", link: "about" },
  { text: "Citizen’s Anatomy", link: "anatomy" },
  { text: "Roadmap", link: "roadmap" },
  { text: "Team", link: "team" },
  {text: "Utilities", link: "utilities"},
];

export const sliders = [
  { id: 1684, title: "Caucasians", img: rarity1 },
  { id: 572, title: "Asian or Pacific Islanders", img: rarity2 },
  { id: 422, title: "Hispanic or Latinos ", img: rarity3 },
  { id: 289, title: "African Decendants", img: rarity4 },
  { id: 169, title: "LGBTQ", img: rarity5 },
  { id: 148, title: "Uniques", img: rarity6 },
  { id: 42, title: "Native Indigenous", img: rarity7 },
  { id: 7, title: "Revolutionary Figures", img: rarity8 },
];

export const utilities = [
 /* {
    id: 1,
    title: "Project <br/> Backing & <br/> contributions",
    subtitle: "Get PRoject support",
    desc: `All holders will have access to our project contribution program allowing them to present their ideas and talent and receive financial support from DC.`,
    moreText: "Find out more",
    anchor: "#project-backing",
    color: "pink",
  },
  {
    id: 2,
    title: "benefits for<br/> Hybrid NFT<br/>  Holders",
    subtitle: "HYBRID CITIZEN HOLDER",
    desc: "To be Revealed Soon...",
    moreText: "",
    anchor: "#hybrid-holders",
    color: "yellow",
  },*/
  {
    id: 3,
    title: "Full NFT CC0<br/> Ownership<br/> rights",
    subtitle: "OWNERSHIP",
    desc: "All DC NFTs come with full CC0 ownership rights, therefore, granting the holder full rights on the use of its unique NFT.",
    moreText: "Read more",
    anchor: "#ownership",
  },
  {
    id: 4,
    title: "Personalised<br/>  Citizen<br/>  NFT Airdrop",
    subtitle: "7x (OR MORE) DC NFT HOLDER",
    desc: "Holding 7x or more DC NFTs grants you a personalized and tradable DC NFT that looks like you (or anyone you wish) airdropped to your wallet (free).",
    moreText: "Learn more",
    anchor: "#personalised",
  },
 /* {
    id: 5,
    title: "Exclusive<br/>  holder<br/>  Benefits",
    subtitle: "ACCESS",
    desc: `Our exclusive holder content includes:
    <ul>
      <li>Community Access</li>
      <li>Free Original Music (f-555)</li>
      <li>Original Designs</li>
      <li>10% Discount on Merch</li>
    </ul>
    `,
    moreText: "Find out more",
    anchor: "#exclusive",
  },*/
];

export const socials = [
  {
    id: 1,
    url: "https://discord.gg/cSWj6mJTHr",
    icon: discord,
    desc: "Stay tuned to our Discord, the heart of our community. Participate in challenges, win giveaways, get funding for your project and much more. Join us!",
  },
  {
    id: 2,
    url: "https://twitter.com/DepressCitizens",
    desc: "See Twiter",
    color: "black",
  },
  {
    id: 3,
    url: "https://www.instagram.com/depressedcitizens/",
    icon: instagram,
    desc: "IG is our story center. Are you interested to know what will happen to the story and the character developments. See you there!",
  },
  {
    id: 4,
    url: 'https://in.tiktok.com/@depressedcitizens',
    icon: tiktok,
    desc: "In Tiktok you will get the best of our funny videos and extra content about our concept and brand topics. We raise a fist and break the norm of what NFT brands do. Come see!",
  },
];

export const questions = [
  {
    question: <>WHAT DC IS ABOUT</>,
    answer: (
      <>
        We’re an existential and sociological brand looking to unite against
        toxic society and improve ourselves via project-building contributions
        and critical thinking. We must question everything as no one benefits
        from an over-agreable society. And yes, those in control are lying to us
        for their personal benefit. Let’s wake up!
      </>
    ),
    img: questionAbout,
    imgAlt: "About",
    className: "mosaic",
  },
  {
    question: (
      <>
        Artivism as Civilised Rebellion{" "}
        <img src={spotifyLogo} alt="Spotify logo" />
      </>
    ),
    answer: (
      <>
        ARTIVISM expresses itself as a form of civilized rebellion. We are not
        here to point fingers but to unite over the truth via art and
        creativity. A fight against ourselves, because in essence, we know that
        we forge the chains that we wear in life.
      </>
    ),
    content: (
      <>
        <div className="questions__content">
          <a
            href="https://open.spotify.com/playlist/7DlNpT6isLKPrlOBnR0IxA?si=801303fbc13e4856"
            target="_blank"
            rel="noreferrer"
            className="questions__content-icon"
          >
            <div className="img-container">
              <img src={spotify} alt="spotify logo" />
            </div>
          </a>
          <h2 className="questions__content-title">Join Our REBEL Playlist</h2>
          <a
            href="https://open.spotify.com/playlist/7DlNpT6isLKPrlOBnR0IxA?si=801303fbc13e4856"
            className="questions__content-link"
            rel="noreferrer"
            target="_blank"
          >
            <span className="questions__content-link-text fz-19">
              View Playlist
            </span>
            <span className="questions__content-link-icon">
              <span className="img-container">
                <svg className="icon">
                  <use xlinkHref="#spotify" />
                </svg>
              </span>
            </span>
          </a>
        </div>
        <div className="questions__answer-img">
          <div className="img-container">
            <img src={iphone} alt="iphone 12" />
          </div>
        </div>
      </>
    ),
  },
  {
    question: <>Dissatisfied With the System </>,
    answer: (
      <>
        Is there more to life than comparing ourselves to others, and working
        hard just to prove our worth? Envy, greed, fear, and hate are causing
        anxiety and depression. Are we being manipulated? Let’s answer these
        questions, get creative and express ourselves through art.
      </>
    ),
    img: questionDissatisfied,
    imgAlt: "express yourself",
    className: "express",
  },
];

export const anatomyList = [
  { count: "1684", info: "Caucasians" },
  { count: "572", info: "Asian or Pacific Islanders" },
  { count: "422", info: "Hispanic or Latinos" },
  { count: "289", info: "African Decendants" },
  { count: "169", info: "LGBTQ" },
  { count: "148", info: "Uniques" },
  { count: "42", info: "Native Indigenous" },
  { count: "7", info: "Revolutionary Figures" },
];


export const roadmapSwiper = [
  {
    subTitle: "May 09 - 2022",
    title: "launch",
    desc: (
      <>
        <h4 className="roadmap__swiper-desc-title fz-19">
          Rare Reveals along the way
        </h4>
        <p className="roadmap__swiper-desc fz-19">
          We reserved the rarest NFTs for a community reveal. We are very
          excited to show you these as we go.
        </p>
      </>
    ),
  },

  {
    subTitle: "May - October 2022",
    title: "build",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
         During this time we were in the design phase of all our citizens, 
         our aim was to build a 100% handmade collection. Follow us on{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/DepressCitizens"
          >
            Twitter.
          </a>
        </p>
      </>
    ),
  },
  {
    subTitle: "Take my money! 01",
    title: "ART ONLY",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
         It is a handmade project to leave our mark on the blockchain forever
          <br />
          <a href="#more">Learn more</a>
        </p>
      </>
    ),
  },
  {
    subTitle: "Discord & Twitter",
    title: "Referal",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Invite your friends to join our Discord and follow us on Twitter,
          during our Pre-launch phase, we will be giving a lot of waitlists and airdrops. 

          <br />
          <a href="#more">Learn more</a>
        </p>
      </>
    ),
  },
  {
    subTitle: "For your eyes only",
    title: "Sneak Peak",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Our new collection is fire! And it comes with many new discoveries and
          links to our storyline. We re excited to slowly reveal them to you as
          sneak-peaks.
        </p>
      </>
    ),
  },
  {
    subTitle: "Take my Money! 02",
    title: "Challanges",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Throughout the build, we will be doing challenges with our community
          to reward you with giveaways .Come to our Discord to get access <br />
          Let’s get it! <br /> <a href="#more">Learn more</a>
        </p>
      </>
    ),
  },
  {
    subTitle: "Join our Gang!",
    title: "Collabs",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Throughout the build we will collaborate with projects, with artists,
          make awesome discord events, and great content. It’s in DC blood to
          join art and social activism, so.... Let’s talk about revolution!
        </p>
      </>
    ),
  },
  {
    subTitle: "The moment we’ve been waiting for!",
    title: "mint date",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Our mintdate is to be confirmed. Stay tuned to our{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/DepressCitizens"
          >
            Twitter
          </a>{" "}
          to know more. Once we know it, you will too!
          <br />
          HINT: sometime in 2024.
          <br /> <a href="#more">Learn more</a>
        </p>
        {/*<ul className="roadmap__swiper-list">*/}
        {/*    <li className="roadmap__swiper-item fz-19">*/}
        {/*        Original Music*/}
        {/*    </li>*/}
        {/*    <li className="roadmap__swiper-item fz-19">*/}
        {/*        Exclusive designs*/}
        {/*    </li>*/}
        {/*    <li className="roadmap__swiper-item fz-19">*/}
        {/*        Illustration Workshop*/}
        {/*    </li>*/}
        {/*    <li className="roadmap__swiper-item fz-19">*/}
        {/*        Discord Surprises*/}
        {/*    </li>*/}
        {/*    <li className="roadmap__swiper-item fz-19">*/}
        {/*        And more...*/}
        {/*    </li>*/}
        {/*</ul>*/}
      </>
    ),
  },
  {
    subTitle: "4 days after mint",
    title: "Reveal",
    desc: (
      <>
        <p className="roadmap__swiper-desc fz-19">
          Our collection will remain hidden untill 4 days after the mint. The
          reveal will be an interesting time to fing out what citizens you got
          and if you can mint our rarest HYBRID CITIZENS. They come with unique
          utilities and rewards. <a href="#more">Learn more</a>
        </p>
      </>
    ),
  },
//  {
  //  subTitle: "You get VIP treatment.",
   // title: "exclusive",
  //  desc: (
//      <>
  //      <p className="roadmap__swiper-desc fz-19">
  //        Holding a DC NFT grants you many exclusive contents:{" "}
  //        <strong>
   //         {" "}
   //         Original Music (F-555) - Exclusive designs - Illustration Workshops
    //        - Discord Surprises - And more... <a href="#more">Learn more</a>
  //        </strong>
   //     </p>
  //    </>
//    ),
//  },
//  {
 //   subTitle: "Whaat?",
 //   title: "season 2",
//    desc: (
 //     <>
  //      <p className="roadmap__swiper-desc fz-19">
  //        We have many ideas to bring to our project. Season 2 will come!
 //       </p>
 //     </>
//    ),
//  },
];

export const teamInfo = [
  {
    img: juls,
    imgAlt: "Juls",
    name: "Juls",
    icons: [
      { icon: "behance", link: "https://www.behance.net/Julianah" },
      {
        icon: "insta",
        link: "https://www.instagram.com/juls_illustrations/",
      },
    ],
    position: "Illustrator / Networking",
    desc: (
      <>
        Juls is a professional illustrator with experience in sales and human
        resource management. She is a Colombian woman affected by the
        inequality, prejudice and stereotypes embedded in the society she is
        from. These experiences inspired her to start Depressed Citizens; a
        project created to make positive change, unite over toxic society and
        open the minds of those willing to question our status quo.
      </>
    ),
  },
  {
    img: tino,
    imgAlt: "Tino Mendes",
    name: "Tino Mendes",
    icons: [
      { icon: "behance", link: "https://www.behance.net/tinomendes" },
      {
        icon: "web",
        link: "https://www.nomadatoast.com",
      },
    ],
    position: "Designer / Communication",
    desc: (
      <>
        Tino is a professional branding designer and marketing expert with a
        background in advertising and music composition. He has 10 years of
        expertise in the field and is responsible for designing our brand, and
        our music, editing videos, story writing, creating content, and visually
        supporting our brand ideas. His design portfolio is a great
        representation of his talent and creativity.
      </>
    ),
  },
  {
    img: ben,
    imgAlt: "Ben",
    name: "Benj_pirate",
    icons: [{ icon: "", link: "/" }],
    position: "Advisor / Business Manager",
    desc: (
      <>
        Ben is a member of the{" "}
        <a
          href="https://twitter.com/DegenScore"
          rel="noreferrer"
          target="_blank"
        >
          OG Degen Citadel,
        </a>{" "}
        ranked 108th on the leaderboard with a degenscore of 870, but most
        importantly, we have their full support for the DC project. He is also a
        Moonbird holder and a professional crypto investor. He is a boss at the
        end of the blockchain and an experienced business manager. There is no
        better guide than one who knows the land like the back of his hand.
      </>
    ),
  },
  /*{
    img: eric,
    imgAlt: "eric",
    name: "Eric / Clic",
    icons: [{ icon: "", link: "/" }],
    position: "Senior Software Developer",
    desc: (
      <>
        Clic is an IT engineer with over 12 years of experience in designing and
        coding software for several multi-national companies in the US and in
        Europe, and has worked on the side as an independent blockchain
        developer for the last couple of years. Treading the fine line between
        white and black hat, he also advises the various projects he comes
        across in terms of design and security, making him an ideal addition to
        the team.
      </>
    ),
  },*/
];

export const faqQuestions = [
  {
    question: "How do I buy a Depressed Citizen?",
    answer: (
      <>
        <p>
          To buy a DC NFT, once a minting date has been announced on{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/yte9BGhR"
          >
            Discord
          </a>{" "}
          and
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/DepressCitizens"
          >
            Twitter
          </a>
          , click on the “Mint Your Citizens” button on the top navigation bar
          of the website. A full-screen popup will open with the minting
          section. Select the number of NFTs you would like to mint by clicking
          on (+) to add and (-) to remove. You may mint a maximum of 3 NFTs per
          wallet for presale (allowlist) and a total of 11 NFTs per wallet for
          public mint.
        </p>
        <p>
          When the entire collection is sold out, you may find a Depressed
          Citizens NFT in the secondary market: Opensea.io
        </p>

        <p>
          Be aware of scams or fake websites. Find the official links in our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://linktr.ee/depressedcitizens"
          >
            link.tree
          </a>
        </p>
      </>
    ),
  },
  {
    question: "What is the total supply?",
    answer: (
      <>
        The collection encompasses a total of 3333 unique, hand-illustrated
        Citizens.
      </>
    ),
  },
  {
    question: "What is the maximum mint amount per wallet?",
    answer: (
      <>
        The maximum of NFTs per wallet is 11. However, you may mint a maximum of
        3 NFTs per presale wallet (allowlist), and an extra 8 NFTs for a public
        mint wallet for a total of 11 NFTs per wallet. Or simply 11 NFTs for
        public mint.
      </>
    ),
  },
  {
    question: "How many NFTs can I mint for the whitelist presale?",
    answer: <>A maximum of 3 NFTs per wallet for presale (allowlist).</>,
  },
  {
    question: "How to mint?",
    answer: (
      <>
        <p>
          Once a minting date has been announced on{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/yte9BGhR"
          >
            Discord
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/DepressCitizens"
          >
            Twitter
          </a>
          , to mint a Depressed Citizen NFT, click on the “Mint Your Citizens”
          button on the top navigation bar. A full-screen popup will open with
          the minting section. Select the number of NFTs you would like to mint
          by clicking on (+) to add and (-) to remove from the total. You may
          mint a maximum of 7 NFTs per presale wallet and 11 NFTs for public
          mint.{" "}
        </p>
        <p>
          Be aware of scams or fake websites. Find the official links in our
          <a
            target="_blank"
            rel="noreferrer"
            href="https://linktr.ee/depressedcitizens"
          >
            link.tree
          </a>
        </p>
      </>
    ),
  },
  {
    question: "What is the price for a Depressed Citizen NFT?",
    answer: (
      <>
        The DC prices are:
        <br />
        TBA for Public Sale
        <br />
        TBA for Allowlist / Presale
        <br />
      </>
    ),
  },
  {
    question: "What is Depressed Citizens?",
    answer: (
      <>
        <p>
          DC is an NFT collection of 3333 unique, hand-illustrated Citizens. We
          are an urban NFT project with community at its heart. We’re an
          artistic window to self-expression, with a social-activism tone that
          unites us as struggling humans.{" "}
        </p>
        <p>Learn more about our concept in our website.</p>
      </>
    ),
  },
  {
    question: "What are the benefits of owning a Depressed Citizen?",
    answer: (
      <>
        <p>
          A DC owner gets exclusive access to our gated{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/yte9BGhR"
          >
            Discord
          </a>{" "}
          channels, access to upcoming DC drops before anyone else and a
          deciding voice in the future of the project, and a governance voice to
          contribute to a social cause. A holder with 7x DC NFTs or more gets a
          unique-personalized NFT.{" "}
        </p>
        <p>
          Check out our Utilities section on our website for a more visual
          explanation of our benefits.{" "}
        </p>
      </>
    ),
  },
  {
    question: "Will there be giveaways?",
    answer: (
      <>
        <p>
          2993 AirDrop & 7 Auction Sales
        </p>
        <p>
          Find the official links in our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://linktr.ee/depressedcitizens"
          >
            link.tree
          </a>
        </p>
      </>
    ),
  },
];

export const POLL = {
  id: "633465af9e9c2c0010ee31e1",
  identifier: "HEAHDYE888",
};

export const polls = [
  {
    id: "633465af9e9c2c0010ee31e2",
    img: poll1,
    title: "Education",
    text: "Knowledge is power. Let’s educate so we can help those make informed decisions.",
  },
  {
    id: "633465af9e9c2c0010ee31e3",
    img: poll2,
    title: "Tech Opportunities",
    text: "Technology is the future. Give tech opportunities to those that don’t have access.",
  },
  {
    id: "633465af9e9c2c0010ee31e4",
    img: poll3,
    title: "Mental Health",
    text: "A clear mind is a clear life. Help those affected overcome their mental barriers.",
  },
];

export const collection = [
  { id: 1, name: "DEPRESSED CITIZEN", img: collection1 },
  { id: 2, name: "DEGEN CITIZEN", img: collection2 },
  { id: 3, name: "DEPRESSED CITIZEN", img: collection3 },
  { id: 4, name: "HYBRID CITIZEN ", img: collection4 },
  { id: 5, name: "DEPRESSED CITIZEN", img: collection5 },
  { id: 6, name: "DEGEN CITIZEN", img: collection6 },
];

export const collectionDots = {
  dc: [
    { id: 1, name: "Headwear", desc: "To be revealed" },
    { id: 2, name: "Hair Styles & COLOR", desc: "To be revealed" },
    { id: 3, name: "Jewellery", desc: "To be revealed" },
    { id: 4, name: "Beard", desc: "To be revealed" },
    { id: 5, name: "Make UP & Skin Tones", desc: "To be revealed" },
    { id: 6, name: "Eye Brows", desc: "To be revealed" },
    { id: 7, name: "Eyes & Glasses", desc: "To be revealed" },
    { id: 8, name: "Nose", desc: "To be revealed" },
    { id: 9, name: "Tattoos", desc: "To be revealed" },
    { id: 10, name: "Clothes", desc: "To be revealed" },
    { id: 11, name: "Pets", desc: "To be revealed" },
    { id: 12, name: "33% Men - 66% Women", desc: "To be revealed" },
  ],
};


export const sliderCharacters = [
  {
    id: 2,
    img: chMainGuy,
    title: 'DEPRESSED CITIZENS',
    desc: 'These alienated critical thinkers broke free from societies’ control to form a rebellion named “Depressed Citizens”. This underground alliance recognized the mental issues that they were facing and the necessity to unite and fight for freedom.'
  },
  {
    id: 1,
    img: chHybrid,
    title: 'SLEEPER CITIZENS',
    desc: 'The Sleepers are an obedient and brainwashed majority of the population who are harvested to generate value for the selfish benefit of THEI. But with  Formula-555 they can be converted back into Depressed Citizens and regain their freedom. (not in the collection)'
  },
  {
    id: 3,
    img: chReveal,
    title: 'DEGEN CITIZENS',
    desc: 'With Formula-555, Depressed Citizens converted enough Sleepers to create an army of Degen Citizens, a geared-up group of citizens angered by the state of their society and ready for Revolution.'
  },
  {
    id: 3,
    img: chReveal,
    title: 'HYBRID CITIZENS',
    desc: 'No one knows why or how, but 1/3 of converted Sleepers are turned into a rather odd type of citizen: The Hybrids. They are highly compassionate and ironically emotionless with an almost monastic vibe. They carry out the sole purpose of helping Depressed Citizens.'
  },

]
