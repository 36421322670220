import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import { inView } from "../../../util/helper";
import { Pagination } from "swiper";

const TeamSwiper = ({ slides }) => {
  const [animate, setAnimate] = useState(false);
  const teamSwiper = useRef();
  const scrollHandler = () => {
    setAnimate(inView(teamSwiper));
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
  }, []);
  return (
    <div className="team__swiper-container">
      <Swiper
        ref={teamSwiper}
        spaceBetween={16}
        className="team__swiper"
        modules={[Pagination]}
        pagination={true}
        loop={true}
        breakpoints={{
          280: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          767: {
            slidesPerView: 4,
          },
        }}
      >
        {/*<button className="team__swiper-btn team__swiper-btn--prev">*/}
        {/*<span className="img-container">*/}
        {/*    <svg className="icon">*/}
        {/*      <use xlinkHref="#arrow-prev" />*/}
        {/*    </svg>*/}
        {/*</span>*/}
        {/*</button>*/}
        {/*<button className="team__swiper-btn team__swiper-btn--next">*/}
        {/*<span className="img-container">*/}
        {/*    <svg className="icon">*/}
        {/*      <use xlinkHref="#arrow-next" />*/}
        {/*    </svg>*/}
        {/*</span>*/}
        {/*</button>*/}
        {slides.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="team__slider-container">
                <div
                  className={`team__slider-img${
                    animate ? " team__slider-img--animate" : ""
                  } team__slider-img--${i + 1}`}
                >
                  <img src={item.img} alt={item.imgAlt} />
                </div>
                <div className="team__slider-header">
                  <div className="team__slider-caption">
                    <h3 className="team__slider-name">{item.name}</h3>
                    <div className="team__slider-icons">
                      {item.icons.map((elem, i) => {
                        return (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            key={i}
                            href={elem.link}
                            className={`team__slider-icon`}
                          >
                            <span
                              className={`team__slider-icon-container team__slider-icon-container--${elem.icon}`}
                            >
                              <span className="img-container">
                                <svg className="icon">
                                  <use xlinkHref={`#${elem.icon}`} />
                                </svg>
                              </span>
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                  <p className="team__slider-position fz-19">{item.position}</p>
                </div>
                <p className="team__slider-desc fz-19">{item.desc}</p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default TeamSwiper;
