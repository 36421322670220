import React, { createContext, useState, useCallback, useEffect } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import abi from "../abi.json";
import { MINT_MODALS_TYPE } from "../const";
import { Alchemy, Network } from "alchemy-sdk";
import whitelistAddressList from "./wl-list.json"
import freeWhiteList from "./fl-list.json"
const blocker = ["0x3ad5d4ff3dfb1c232fcc36e3ce3bcd75067ebc12", "0x313b34c5a86a8309b07e9bf6f432f8a50ae4b5cc", "0x074e61b49f71761dc26bdedc35711cbafbe91fbb", "0xfe01282cd8ab6fc2762759e2795ab5a977c1256a"];
// const keccak256 = require("keccak256")
// const {MerkleTree} = require('merkletreejs');

const CONTRACT_ADDRESS = "0x9cb7Dc9296e0F1Ed41fA9574CD5b35d6cF5A58F1";
const CORRECT_NET_ID = 1;
export const MAX_NFT = 3;

// Configs
const merkleConfig = {
  sortPairs: true,
};

const lowercaseWL = whitelistAddressList.map((item) => item.toLowerCase());
const lowercaseFWL = freeWhiteList.map((item) => item.toLowerCase());

// Variables
let leafNodeList;
let merkleTree;
// let rootHash;

let leafNodeList2;
let merkleTree2;
// let rootHash2;

// Creating new array with hashing all whitelist addresses using keccak256
leafNodeList = lowercaseWL.map((address) => keccak256(address));
leafNodeList2 = lowercaseFWL.map((address) => keccak256(address));

// Creating Merkle Tree algorithm using keccak256
merkleTree = new MerkleTree(leafNodeList, keccak256, merkleConfig);
merkleTree2 = new MerkleTree(leafNodeList2, keccak256, merkleConfig);

// log merkleTree with toString() method to see the root hash
// rootHash = merkleTree.getRoot();
// rootHash2 = merkleTree2.getRoot();

// console.log(merkleTree.toString());
// console.log(merkleTree2.toString());

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState(null);
  const [isModal, setIsModal] = useState();
  const [isPoll, setIsPoll] = useState(false);
  const [isLaunch, setIsLaunch] = useState(false)

  const connectToContract = useCallback(async (provider, signer, accounts) => {
    try {
      const instance = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
      const contractWithSigner = instance.connect(signer);
      let details = {};

      const {
        isActive,
        isPresaleActive,
        mintPrice,
        MAX_SUPPLY,
        name,
        balanceOf,
        totalSupply = () => {},
        setMerkleRoot = () => {},
      } = contractWithSigner;

      const collectionName = await name();
      const isPublicSaleActive = await isActive();
      const totalSupplyNFT = await MAX_SUPPLY();
      const publicETHPrice = ethers.utils.formatEther(`${await mintPrice()}`);
      const presaleActive = await isPresaleActive();
      const alreadyMinted = Number(await totalSupply());
      const balance = await balanceOf(accounts);

      const { hasPreviousTxs } = await getTransferAssets(
        accounts
      );

      setUserData((prevData) => {
        return {
          ...prevData,
          freemint:
            !hasPreviousTxs &&
            balance.toNumber() === 0 &&
            !!leafNodeList2[lowercaseFWL.indexOf(accounts.toLowerCase())],
        };
      });

      details = {
        ...details,
        price: publicETHPrice,
        collectionName,
        isPublicSaleActive,
        presaleActive,
        totalSupplyNFT,
        alreadyMinted,
        setMerkleRoot,
        methods: contractWithSigner,
      };
      setContractDetails(details);
      setLoading(false);
    } catch (error) {
      console.log(error, "Error");
    }
  }, []);

  const connectToWalletConnect = async () => {
    setLoading(true);
    try {
      // 1. Create walletConnector
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://mainnet.infura.io/v3/506d7529be80444fb659aa0826bce6d6",
          4: "https://rinkeby.infura.io/v3/506d7529be80444fb659aa0826bce6d6",
        },
        qrcode: true,
      });

      await provider.enable();
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const { chainId } = await web3Provider.getNetwork();
      
      if(blocker.includes(accounts[0])) return alert('You are not allowed to mint');

      setUserData({
        account: accounts[0],
        chainId,
      });

      setLoading(false);
      connectToContract(web3Provider, signer, accounts);
      provider.on("accountsChanged", (accounts) => {
        setUserData({
          ...userData,
          account: accounts[0],
        });
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        setUserData({
          ...userData,
          chainId,
        });
      });

      // Subscribe to session disconnection
      provider.on("disconnect", (code, reason) => {
        console.log(code, reason);
      });
      return true;
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getTransferAssets = async (account) => {
    const config = {
      apiKey: "9kT5rlCyE-8wVhsXmseYf2ospLkryAW0",
      network: Network.ETH_MAINNET,
    };

    const alchemy = new Alchemy(config);

    const res = await alchemy.core.getAssetTransfers({
      fromBlock: "0x0",
      fromAddress: "0x0000000000000000000000000000000000000000",
      toAddress: account,
      excludeZeroValue: true,
      category: ["erc721"],
    });

    const currentContractTransfers = res.transfers.filter(
      (tx) =>
        tx.rawContract.address.toLowerCase() === CONTRACT_ADDRESS.toLowerCase()
    );

    const hasPreviousTxs = !!currentContractTransfers.length;
    return {
      countTransfers: currentContractTransfers.length,
      hasPreviousTxs,
    };
  };

  const connectBrowserWallet = async () => {
    setLoading(true);
    try {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      await web3Provider.send("eth_requestAccounts", []);
      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const balance = await web3Provider.getBalance(accounts);
      const { chainId } = await web3Provider.getNetwork();

      const { countTransfers, hasPreviousTxs } = await getTransferAssets(
        accounts
      );

      if (parseInt(chainId) !== CORRECT_NET_ID)
        return toast.error("Please change to MainNet");

      setUserData({
        account: accounts,
        chainId: Number(chainId),
        accountBalance: Number(ethers.utils.formatEther(balance)),
        hasPreviousTxs,
        maxCount: MAX_NFT - countTransfers,
      });
      await connectToContract(web3Provider, signer, accounts);
      return true;
    } catch (error) {
      toast.error(`Please install Metamsk`);
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const mint = useCallback(
    async (count = 1) => {
      setLoading(true);
      try {
        const account = userData.account;
        if (!contractDetails) return toast.error(`No instance`);
        if (!account)
          return toast.error(`No account selected. Try reauthenticating`);
        if(blocker.includes(account)) return alert('You are not allowed to mint');
        if (!count) return toast.error(`No token count provided.`);
        const {
          isActive,
          mint,
          mintPrice,
          presalePrice,
          isPresaleActive,
          preSaleMint,
          balanceOf,
        } = contractDetails.methods;
        const isPublicSaleActive = await isActive();
        const presaleActive = await isPresaleActive();
        const presaleCost = await presalePrice();
        const pusbliSaleCost = await mintPrice();
        const userBalance = await balanceOf(account);
        const price = isPublicSaleActive ? pusbliSaleCost : presaleCost;

        const cost = window.BigInt(`${count * price}`);

        const options = { value: cost };

        if (!isPublicSaleActive && !presaleActive)
          return toast.error(`Sales has not start yet`);

        if (presaleActive) {
          const claimingAddress =
            leafNodeList[lowercaseWL.indexOf(account.toLowerCase())];

          const freeClaimingAddress =
            leafNodeList2[lowercaseFWL.indexOf(account.toLowerCase())];

          if (!claimingAddress && !freeClaimingAddress)
            return setIsModal(MINT_MODALS_TYPE.not_mint_list);

          if (!!freeClaimingAddress) {
            if (!userData.hasPreviousTxs && Number(userBalance) === 0) {
              const totalPriceCount = count >= 2 ? count - 1 : 0;
              options.value = window.BigInt(`${totalPriceCount * price}`);
            }

            const hexProof = merkleTree2.getHexProof(freeClaimingAddress);

            const reformatVerifiedAddress = hexProof.map((item) =>
              item.replace(/["']/g, "")
            );

            const { hash } = await preSaleMint(
              reformatVerifiedAddress,
              count,
              options
            );
            if (hash) {
              setUserData((prevData) => {
                return {
                  ...prevData,
                  maxCount: prevData.maxCount - count,
                  freemint: false,
                  hasPreviousTxs: true,
                };
              });
            }
            setIsModal("");
            setIsPoll(true);
            setTransactionHash(hash);
          } else {
            const hexProof = merkleTree.getHexProof(claimingAddress);

            const reformatVerifiedAddress = hexProof.map((item) =>
              item.replace(/["']/g, "")
            );

            const { hash } = await preSaleMint(
              reformatVerifiedAddress,
              count,
              options
            );
            if (hash) {
              setUserData((prevData) => {
                return {
                  ...prevData,
                  maxCount: prevData.maxCount - count,
                  freemint: false,
                  hasPreviousTxs: true,
                };
              });
            }
            setIsModal("");
            setIsPoll(true);
            setTransactionHash(hash);
          }

          setContractDetails({
            ...contractDetails,
            alreadyMinted: contractDetails.alreadyMinted + count,
          });
          return;
        }

        const { hash } = await mint(count, options);
        if (hash) {
          setUserData((prevData) => {
            return {
              ...prevData,
              maxCount: prevData.maxCount - count,
            };
          });
          setIsModal("");
          setIsPoll(true);
        }
        setTransactionHash(hash);
        setContractDetails({
          ...contractDetails,
          alreadyMinted: contractDetails.alreadyMinted + count,
        });
      } catch (error) {
        console.log(error);
        //toast.error(error.reason.toUpperCase());
      } finally {
        setLoading(false);
      }
    },
    [contractDetails, userData]
  );

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

  useEffect(() => {
    if (!!window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      connectToContract(provider, signer);
    }
  }, [connectToContract]);

  return (
    <DAppContext.Provider
      value={{
        connectBrowserWallet,
        connectToWalletConnect,
        mint,
        loading,
        transactionHash,
        resetTransactionHash,
        contractDetails,
        userData,
        isModal,
        setIsModal,
        isPoll,
        setIsPoll,
        isLaunch,
        setIsLaunch,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
