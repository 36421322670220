import React, { useEffect } from "react";
import "./style.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { chevron, chevronl } from "../../../assets";

const RoadmapSwiper = ({ slides }) => {
  useEffect(() => {
    const swiper = document.querySelector(".roadmap__swiper");
    swiper.style.width = "99%";
    swiper.style.boxSizing = "border-box";
    window.addEventListener("load", () => {
      swiper.style.width = "100%";
    });
    window.removeEventListener("load", () => {
      swiper.style.width = "100%";
    });
  }, []);

  return (
    <div className="roadmap__wrapper">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        initialSlide={1}
        navigation={{
          nextEl: ".roadmap__swiper-btn--next",
          prevEl: ".roadmap__swiper-btn--prev",
        }}
        modules={[Navigation]}
        className="roadmap__swiper"
        loop={true}
        breakpoints={{
          280: {
            centeredSlides: false,
          },
          667: {
            centeredSlides: true,
          },
        }}
      >
        <button className="roadmap__swiper-btn--next">
          <span className="roadmap__swiper-btn-text fz-19">Next</span>
          <img className="chevron-r" src={chevron} alt="chevron" />
        </button>
        <button className="roadmap__swiper-btn--prev">
         <img className="chevron-l" src={chevronl} alt="Chevron" />
        </button>
        {slides.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="roadmap__swiper-container">
                <p className="roadmap__swiper-sub-title">{item.subTitle}</p>
                <h3 className="roadmap__swiper-title">
                  <span>{item.title}</span> -
                </h3>
                <div className="roadmap__swiper-inner-container">
                  {item.desc}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default RoadmapSwiper;
