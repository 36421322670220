import cn from "classnames";
import { useState } from "react";
import { borderTop, chevron } from "../../assets";
import { sliderCharacters } from "../../data";
import styles from "./index.module.scss";

const CharacterSlider = () => {
  const [activeId, setActiveId] = useState(0);
  const lastIndex = sliderCharacters.length - 1;

  const handleNext = () => {
    setActiveId((prev) => (prev < lastIndex ? prev + 1 : 0));
  };

  const handlePrev = () => {
    setActiveId((prev) => (prev === 0 ? lastIndex : prev - 1));
  };

  const activeItem = sliderCharacters[activeId];
  const prevItem =
    activeId === 0
      ? sliderCharacters[lastIndex]
      : sliderCharacters[activeId - 1];
  const nextItem =
    activeId === lastIndex
      ? sliderCharacters[0]
      : sliderCharacters[activeId + 1];

  let startClientX;

  const handleTouchStart = (e) => {
    startClientX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const clientX = e.touches[0].clientX;

    if (startClientX && clientX) {
      if (clientX > startClientX) {
        handleNext();
      } else {
        handlePrev();
      }
    }
  };

  return (
    <section className={styles["slider"]}>
      <img className={styles["slider__border"]} src={borderTop} alt="Border" />
      <div className={styles["scroll-text"]}>
        <h2>
          Uniting against Toxic society Uniting against Toxic society Uniting
          against Toxic society Uniting against Toxic society Uniting against
          Toxic society Uniting against Toxic society Uniting against Toxic
          society
        </h2>
      </div>
      <div className="page-container">
        <div className={styles["slider__wrap"]}>
          <div className={styles["info"]}>
            <div className={styles["info__head"]}>
              <h3 className={styles["info__title"]}>{activeItem.title}</h3>
              <button onClick={handleNext} className={styles["info__btn_next"]}>
                <span>Next</span>
                <img className="chevron-r" src={chevron} alt="chevron" />
              </button>
            </div>
            <p>{activeItem.desc}</p>
            <div className={styles["info__btns"]}>
              <a className={cn("btn", styles["info__btn_story"])} href="#story">
                Follow Story
                <svg>
                  <use xlinkHref="#arrow-bottom" />
                </svg>
              </a>
              <a
                className={cn("btn btn__pink", styles["info__btn_share"])}
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/DepressCitizens"
              >
                Join Our Twitter{" "}
                <svg>
                  <use xlinkHref="#twitter" />
                </svg>
              </a>
            </div>
          </div>
          <div>
            <div className={styles["images"]}>
              <img
                className={styles["images__prev"]}
                src={prevItem.img}
                alt={prevItem.title}
              />
              <img
                className={styles["images__active"]}
                src={activeItem.img}
                alt={activeItem.title}
                onTouchMove={handleTouchMove}
                onTouchStart={handleTouchStart}
              />
              <img
                className={styles["images__next"]}
                src={nextItem.img}
                alt={nextItem.title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CharacterSlider;
