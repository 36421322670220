import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import Nav from "./Nav";
import logo from "../../../assets/img/logo-white.svg";
import HeaderIcons from "./HeaderIcons";
import { navList } from "../../../data";
import { PopupContext } from "../../../context/popup";
import Timer from "../../../components/timer";
import { DAppContext } from "../../../context/dapp";

const Header = ({ active }) => {
  const [hidden, setHidden] = useState(true);
  const [val, setVal] = useContext(PopupContext);
  const { isLaunch } = useContext(DAppContext);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (val !== "") {
      hamburgerCheck.current.checked = false;
    }
  }, [val]);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY !== 0) {
        setScroll(true);
      } else if (window.scrollY === 0) {
        setScroll(false);
      }
    });
  }, []);
  const changeHandler = () => {
    if (hidden) {
      setVal("");
      setHidden(!hidden);
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    } else if (!hidden) {
      document.body.style.overflow = null;
      document.body.style.touchAction = null;
    }
    setHidden(!hidden);
  };
  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const hamburgerCheck = useRef();

  return (
    <header
      className={`header${val ? " header--active" : ""}${
        scroll ? " header--scroll" : ""
      }`}
    >
      <div className="header__container">
        <div className="header--mobile">
          <button
            ref={hamburgerCheck}
            className="hamburger"
            onClick={changeHandler}
          >
            <span className="img-container">
              <svg className="icon">
                <use xlinkHref="#hamburger" />
              </svg>
            </span>
          </button>
          {!isLaunch && (
            <div className="header-timer">
              <a href="#mint-info">
                <Timer  theme="header" />
              </a>
            </div>
          )}
          <HeaderIcons
            headerIconsData={[
              { xLink: "discord", link: "https://discord.gg/cSWj6mJTHr" },
              {
                xLink: "insta",
                link: "https://www.instagram.com/depressedcitizens/",
              },
              { xLink: "twitter", link: "https://twitter.com/DepressCitizens" },
            ]}
          />
          <button className="logo logo--mobile">
            <span className="img-container">
              <img src={logo} alt="page-logo" />
            </span>
          </button>
          {/* {isLaunch && (
            <button
              className={`header-right__button fz-19${
                val === "mint" ? ` header-right__button--active` : ""
              }`}
              onClick={() => (val !== "" ? setVal("") : setVal("mint"))}
            >
              Mint Your Citizens
            </button>
          )} */}
        </div>
        <div
          className={`header__wrapper-mobile${
            !hidden ? " header__wrapper-mobile--active" : ""
          }`}
        >
          <button className="nav__close-btn" onClick={changeHandler}>
            <span className="img-container">
              <svg className="icon">
                <use xlinkHref="#close" />
              </svg>
            </span>
          </button>
          <Nav
            setHidden={() => changeHandler()}
            active={active}
            navListData={navList}
          />
          <button className="logo">
            <span className="img-container" onClick={scrollHandler}>
              <img src={logo} alt="page-logo" />
            </span>
          </button>
          {!isLaunch && (
            <div className="header-timer hide-md">
              <a href="#mint-info">
                <Timer theme="header" />
              </a>
            </div>
          )}
          <a href="#intro" className="btn_story">
            WATCH STORY <span />
          </a>
          <div className="header-right">
            {/* {isLaunch && (
              <button
                className={`header-right__button fz-19${
                  val === "mint" ? ` header-right__button--active` : ""
                }`}
                onClick={() => (val !== "" ? setVal("") : setVal("mint"))}
              >
                Mint Your Citizens
              </button>
            )} */}
            {/* <button
              className={`header-right__button header-right__button_black fz-19${
                val === "video" ? ` header-right__button--active` : ""
              }`}
              onClick={() => (val !== "" ? setVal("") : setVal("video"))}
            >
              HOW TO MINT? <span />
            </button> */}
            <HeaderIcons
              headerIconsData={[
                { xLink: "discord", link: "https://discord.gg/cSWj6mJTHr" },
                {
                  xLink: "insta",
                  link: "https://www.instagram.com/depressedcitizens/",
                },
                {
                  xLink: "twitter",
                  link: "https://twitter.com/DepressCitizens",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
