import React from "react";
import './style.scss';
import TeamSwiper from "./TeamSwiper";
import {teamInfo} from "../../data";

const Team = () => {
    return (
        <section className='team' id='team'>
            <div className="team-wrapper">
                <div className="page-container">
                    <div className="team__container">
                        <h2 className="team__title">
                            the Team
                        </h2>
                        <TeamSwiper slides={teamInfo}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team