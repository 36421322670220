import React from "react";
import './style.scss';
import {PopupHeader} from "../index";

const Contract = () => {
    return (
        <>
            <div className="faq">
                <PopupHeader caption={'Verified Smart Contract Address'}/>
                <div className="page-container">
                    <div className="popup__container">
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contract;