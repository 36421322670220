import React, { useState } from "react";
import "./style.scss";
import { PopupHeader } from "../index";
import stranger from "../../../assets/img/mint/stranger.png";
import { useContext } from "react";
import { DAppContext, MAX_NFT } from "../../../context/dapp";
import Loader from "../../../components/loader";
import ModalMintMsgs from "../MintMsgs";
import { MINT_MODALS_TYPE } from "../../../const";
import { useEffect } from "react";

const Mint = () => {
  const { connectBrowserWallet, userData, mint, loading, isModal, setIsModal } =
    useContext(DAppContext);
  const [value, setValue] = useState(1);
  const plusHandler = () => value < userData?.maxCount && setValue(value + 1);
  const minusHandler = () => value > 1 && setValue(value - 1);

  const handleMint = () => {
    if (userData.freemint) {
      setIsModal(MINT_MODALS_TYPE.freemint);
    } else {
      mint(value);
    }
  };

  useEffect(() => {
    if(userData?.maxCount){
      setValue(userData?.maxCount);
    }
  }, [userData]);

  return (
    <>
      <div className="faq">
        <PopupHeader
          popupName={"mint"}
          caption={"ALLOWLIST + FREEMINT OPEN 24hs"}
        />
        <div className="page-container">
          <div className="popup__container popup__container--mint">
            <div className="mint__content">
              <h2 className="mint__title">Select your mint amount</h2>
              <p className="mint__desc fz-19">
                You may mint a max of 3 NFTs per wallet for the Allowlist &
                Freemint date, and a max of 11 NFTs per wallet for the public
                mint (including any NFTs acquired during the presale). Connect your wallet and select the amount.
              </p>
              <form className="mint__buttons-container">
                <label htmlFor="mint-price" className="mint__button-label">
                  <button
                    onClick={minusHandler}
                    type="button"
                    className="mint__input-btn mint__input-btn--minus"
                  >
                    <span className="mint__input-btn-img">
                      <span className="img-container">
                        <svg className="icon">
                          <use xlinkHref="#minus" />
                        </svg>
                      </span>
                    </span>
                  </button>
                  <input
                    value={value}
                    type="number"
                    className="mint__input"
                    id="mint-price"
                    disabled
                  />

                  <button
                    onClick={plusHandler}
                    type="button"
                    className="mint__input-btn mint__input-btn--plus"
                  >
                    <span className="mint__input-btn-img">
                      <span className="img-container">
                        <svg className="icon">
                          <use xlinkHref="#plus" />
                        </svg>
                      </span>
                    </span>
                  </button>
                </label>

                {userData ? (
                  userData.maxCount > 0 ? (
                    <button
                      onClick={handleMint}
                      type="button"
                      className="mint__button fz-19"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Mint"}
                    </button>
                  ) : (
                    <h3 className="text-white">
                      You have already minted {MAX_NFT} NFTs
                    </h3>
                  )
                ) : (
                  <button
                    onClick={connectBrowserWallet}
                    type="button"
                    className="mint__button fz-19"
                  >
                    {loading ? <Loader /> : "Connect Wallet"}
                  </button>
                )}
              </form>
              {userData && (
                <p className="mint__account">
                  Account:{" "}
                  <span>
                    {userData.account.slice(0, 4)}...
                    {userData.account.slice(-4)}
                  </span>{" "}
                </p>
              )}
              <p className="mint__price fz-19">
                <span>ALLOWLIST & FREEMINTS:</span> Sep 30th (5pm UTC) - Oct 1st
                <br />
                (5pm UTC) <br />
                <span>PUBLIC MINT: </span> Oct 1st (6pm UTC) <br />
                <span>PRICE:</span> AL 0.066eth / PM 0.077eth
                <br />
                <span>GAS FEE:</span> One single gas fee per transaction
              </p>
            </div>
            <div className="mint__image-container">
              <div className="img-container">
                <img src={stranger} alt="stranger" />
              </div>
              <div className="mint__image-text">?</div>
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <ModalMintMsgs
          mint={mint}
          type={isModal}
          setIsModal={setIsModal}
          loading={loading}
        />
      )}
    </>
  );
};

export default Mint;
