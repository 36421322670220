import "./styles.scss";
import introVideo from "../../assets/video/intro.mp4";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { inView } from "../../util/helper";
import cn from "classnames";
import {
  centeredImages,
  introCharacter,
  introDivide,
  introTv,
  quadBike,
} from "../../assets";

const Intro = () => {
  const [isPlay, setIsPlay] = useState(false);
  const [isMuted, setIsMuted] = useState({ state: false, important: false });
  const videoRef = useRef(null);
  const introRef = useRef(null);

  const onPlay = () => {
    setIsPlay((prev) => !prev);
    if (isPlay) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const onMute = () => {
    setIsMuted((prev) => ({ state: !prev.state, important: !prev.important }));
    videoRef.current.muted = !isMuted.state;
  };

  useEffect(() => {
    const introScroll = () => {
      setIsMuted((prev) => ({ ...prev, state: !inView(introRef) }));
      videoRef.current.muted = !inView(introRef);
    };
    if (!isMuted.important) {
      window.addEventListener("scroll", introScroll);
    }

    return () => window.removeEventListener("scroll", introScroll);
  }, [isMuted]);

  return (
    <section
      ref={introRef}
      className={`intro ${isPlay ? "hide-bg" : ""}`}
      id="intro"
    >
      <video
        ref={videoRef}
        src={introVideo}
        className={cn("intro__video", { hidden: !isPlay })}
      />
      <div className="page-container">
        <div className={cn({ hidden: isPlay })}>
          <div className="center-block">
            <img
              className="center-block__quad"
              src={quadBike}
              alt="Quad bike"
            />
            <img
              className="center-block__logos"
              src={centeredImages}
              alt="Logos"
            />
            <div className="r-side">
              <img className="r-side__character" src={introCharacter} alt="Character" />
              <img className="r-side__tv" src={introTv} alt="Tv" />
            </div>
          </div>
          <p className="intro__desc">
            Uniting against toxic society. Encouraging critical thinking.
            100% Handmade ART          </p>
        </div>
        <div className="intro__wrap">
          {isPlay && (
            <button onClick={onMute} className="intro__btn-mute">
              <div
                className={`intro__btn-mute__speaker ${
                  isMuted.state ? "muted" : ""
                }`}
              >
                <span />
              </div>
            </button>
          )}
          <button onClick={onPlay} className="intro__btn">
            <div className="intro__btn__play">
              <span className={isPlay ? "paused" : ""} />
            </div>
            <p>{isPlay ? "Pause story" : "Play story"}</p>
          </button>
        </div>
      </div>
      <img className="intro__divide" src={introDivide} alt="Intro" />
    </section>
  );
};

export default Intro;
