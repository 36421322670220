import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { getCitizenBg, getCitizenBuilding, protester } from "../../assets";
import { PopupContext } from "../../context/popup";
import { socials } from "../../data";
import "./index.scss";
import { inView } from "../../util/helper";

const Social = () => {
  const [, setVal] = useContext(PopupContext);
  const [isAnimate, setIsAnimate] = useState({
    building: false,
    protester: false,
  });
  const buildingRef = useRef(null);
  const protesterRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      setIsAnimate({
        building: inView(buildingRef),
        protester: inView(protesterRef),
      });
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <section className="social">
      <div className="page-container">
        <div className="social__head">
          <h2 className="social__title">SOCIAL MEDIA STRUCTURE</h2>
          <p className="social__desc">
            FOLLOW our story, our concept, win giveaways.
          </p>
        </div>
        <div className="social__wrap">
          {socials.map((item) => (
            <div key={item.id} className="social-card__wrap">
              <a
                className="social__link"
                target="_blank"
                rel="noreferrer"
                href={item.url}
              >
                <div
                  className={`social-card social-card_${
                    item.color
                  } social-card_${!item.subtitle && "see"}`}
                  key={item.id}
                >
                  {item.icon ? (
                    <>
                      <div className="social-card__icon">
                        <img src={item.icon} alt="Social" />
                      </div>
                      <p>{item.desc}</p>
                    </>
                  ) : (
                    <button className="social-card__btn">
                      <svg className="icon">
                        <use xlinkHref="#plus" />
                      </svg>

                      <div className="social-card__btn__desc"> {item.desc}</div>
                    </button>
                  )}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="get-citizen">
        <div className="page-container">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/cSWj6mJTHr"
            className="btn btn_pink get-citizen__btn"
          >
            Get Your Citizenship
          </a>
          <div className="get-citizen__bg">
            <img src={getCitizenBg} alt="Get citizen" />
            <img
              ref={protesterRef}
              className={cn("get-citizen__protester", {
                "get-citizen__protester_animate": isAnimate.protester,
              })}
              src={protester}
              alt="Protester"
            />
            <img
              ref={buildingRef}
              className={cn("get-citizen__building", {
                "get-citizen__building_animate": isAnimate.building,
              })}
              src={getCitizenBuilding}
              alt="Get citizen building"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Social;
