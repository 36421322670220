import { PopupHeader } from "../../Home/Popups";
import "./index.scss";

const WhitePaper = ({ setVal }) => {
  return (
    <div className="whitepaper faq">
      <PopupHeader caption={"Whitepaper"} />
      <div className="page-container">
        <div className="popup__container whitepaper__text">
          <h3>WHAT IS DEPRESSED CITIZENS</h3>
          <h4>INTRO</h4>
          <p>
            Depressed Citizens is a collection of digital art made by Juls (the
            Artist) and stored in Bitcoin network. The DC NFTs will be
            stored as Ordinals; a new way to mint a batch for one single gas
            fee on the securest network there is. The collection is comprised of
            3333 NFTs of 420 by 420px minted through the depressedcitizens.com
            website and magiceden.io for the secondary market.
          </p>
          <h4>LINKS</h4>
          <div className="whitepaper__links">
            <p>
              Website:{" "}
              <a
                href="https://depressedcitizens.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://depressedcitizens.com/
              </a>{" "}
            </p>
            <p>
              Twitter:{" "}
              <a
                href="https://twitter.com/DepressCitizens"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/DepressCitizens
              </a>{" "}
            </p>
            <p>
              Discord:{" "}
              <a
                href="https://t.co/rK177bSkMd"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://t.co/rK177bSkMd
              </a>{" "}
            </p>
            <p>
              Instagram:{" "}
              <a
                href="https://www.instagram.com/depressedcitizens/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.instagram.com/depressedcitizens/
              </a>{" "}
            </p>
            <p>
              TikTok:{" "}
              <a
                href="https://www.tiktok.com/@depressedcitizens"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.tiktok.com/@depressedcitizens
              </a>{" "}
            </p>
          </div>
          <h3 id="ownership">OWNERSHIP RIGHTS</h3>
          <p>
            The ownership of a Depressed Citizens NFT is simple: You as the
            owner, have as much right to your Depressed Citizens NFT as you have
            to your own person. We hope to establish a sense of pride in the
            ownership of a DC NFT and relinquish a piece of the brand to the
            owner. You have full ownership of the unique Depressed Citizen(s)
            you own and may use it in any case scenario you wish but not limited
            to; copy, sell it, use it for personal financial gains, advertise it
            and modify it.
          </p>
          <p>
            One condition: In the event of modifications, if the end result is a
            close representation of any other Depressed Citizens NFT ever sold
            through the depressedcitizens.com website from the collection of
            3333 NFTs made by Juls (the Artist), it may then not be used
            publicly or for personal gain in any case scenario. As an owner, you
            shall respect the uniqueness of the digital art and the many other
            owners by not copying or using another Depressed Citizens NFT that
            you do not own. DC shall not be held accountable for any complaints
            or legal cases issued against a DC NFT owner in the eventual breach
            of this single ownership condition.
          </p>
          <p>
            Any entity, including the Depressed Citizens brand, may not use a
            sold DC NFT for any scenario including but not limited to;
            advertising, marketing, modifications, communication, or brand
            awareness of any kind without the owner’s consent. The Depressed
            Citizens brand holds the right to create new and unique DC NFTs
            beyond the 3333 NFTs sold, to use in our brand communication,
            advertising, marketing, and brand awareness. However, in the
            eventuality that any sold DC NFT becomes a popular brand image, we
            hope to create a secondary revenue stream for the DC NFT owner that
            comes with the ownership rights that are specified herein.
          </p>
          <h3 id="terms">TERMS & CONDITIONS</h3>
          <h4>i. LIMITATION OF LIABILITY & RISKS</h4>
          <p>
            As the owner of a Depressed Citizen NFT, you acknowledge that NFT
            purchase is a very volatile investment, and shall not hold the DC
            brand, DC team members, and anyone affiliated with the Depressed
            Citizens business responsible for any loss that may occur, direct or
            indirect, from a purchase of a DC NFT. The cryptocurrency and web3
            space is a relatively new and unregulated landscape, therefore you
            assume all risks that may prevail from investing in such assets. You
            understand that it is your full responsibility to do your own
            research before buying an NFT and will not take any message,
            beliefs, or communications from the Depressed citizens’ brand as
            investment advice. Children below the age of 18 years are not
            permitted to use this site or purchase a DC NFT for any reason.
          </p>
          
          <h3>VALUES, MISSION, GOALS</h3>
          <p>
            We are an NFT art motivated by a deep intent to wake up our society to social issues that are affecting us all. 
            Issues that are often overlooked because they are so embedded in our lives that it's hard to accept that they are real.
            We would like to link art to social issues and become a platform for self expression and inclusion via our creativity.
          </p>
          <h3>ROADMAP</h3>
          <p>
            <a onClick={() => setVal("")} href="#roadmap">
              {" "}
              See on Home Page {">"}{" "}
            </a>
          </p>
          <h3>BENEFITS</h3>
          <p>DC is promising 6 holder benefits:</p>
          <h4 id="project-backing">1- PROJECT BACKING & CONTRIBUTIONS</h4>
          <p>
            We will be giving back directly to our community in the form of
            project-building contributions. We want to create toxic-free
            mindsets for a success-positive space, by backing projects and
            individuals to overcome barierrs, and raising our community to new
            mental and success levels for a balanced and peaceful lifestyle. THE
            FORMULA: DC believes that in our modern days, these 3 categories
            below combine into a magic formula for a balanced and successful
            life. 1. Education 2. Technology 3. Mental Health. To know more,
            download our PDF doccument.
          </p>

          <h4 id="hybrid-holders">2- Benefits for Hybrid holders</h4>
          <p>To be Revealed Soon...</p>

          <h4>
            3- FULL OWNERSHIP RIGHTS TO THE OWNED DC NFT(S) AS SPECIFIED IN THIS
            DOCUMENT
          </h4>
          <p><a href="#ownership"> See Above {">"} </a></p>

          <h4 id="personalised">
            4- PERSONALIZED CITIZEN AIRDROP TO HOLDERS WHO MINT 7+ DC NFTS
          </h4>
          <p>
            Holding 7 or more DC NFTs in a single wallet grants you a
            personalized and tradable DC NFT that looks like you (or anyone you
            wish) airdropped to your wallet. This benefit is entirely free and
            you may select any person, yourself or a character you would like
            the NFT to look like. The personalized NFT will be stored in the
            blockchain, therefore making it mintable and transferable.
          </p>

          <h4 id="exclusive">5- EXCLUSIVE HOLDER Benefits</h4>
          <p>Our exclusive holder content includes:</p>
          <ul>
            <li>
              Community access and benefits from our post-mint Discord channel.
            </li>
            <li>
              Free Original Music. According to the DC story, F-555 is a magical
              sound that the Depressed Citizens use to awaken the socialy
              dormant majority of the populations (the Sleepers). DC will
              release an original song from an upcoming hiphop artist. This
              track will be free for DC holders and paid for non-holders.
            </li>
            <li>
              Original Designs. We will be releasing a series of designs that
              fit with the DC story and other. These will be sent for free to DC
              holders.
            </li>
            <li>10% Discount on Merch.</li>
          </ul>
          <div className="text-center">
            <a
              onClick={() => setVal("")}
              className="btn btn_white btn_contact-us"
              href="#footer"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitePaper;
