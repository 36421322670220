import React from "react";
import './style.scss';
import RoadmapSwiper from "./RoadmapSwiper";
import {roadmapSwiper} from "../../data";

const Roadmap = () => {
    return (
        <section className='roadmap' id='roadmap'>
            <div className="page-container">
                <h2 className="roadmap__title">
                    Roadmap
                </h2>
            </div>
            <RoadmapSwiper slides={roadmapSwiper}/>
        </section>
    )
}

export default Roadmap