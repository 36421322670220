import React from "react";
import mintVideo from "../../../assets/video/mint.mp4";
import { PopupHeader } from "../index";
import "./style.scss";

const Video = () => {
  
  return (
    <div className="faq">
      <PopupHeader caption={"How to Mint?"} />
      <div className="page-container">
        <div className="popup__container">
          <video controls  src={mintVideo} className="mint-video" />
        </div>
      </div>
    </div>
  );
};

export default Video;
