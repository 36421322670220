import React, { useContext } from "react";
import { DAppContext } from "../context/dapp";
import { PopupContext } from "../context/popup";
import ChapterOne from "./ChapterOne";
import CharacterSlider from "./CharactersSlider";
import Collection from "./Collection";
import Contribution from "./Contribution";
import Info from "./Info";
import Intro from "./Intro";
import Popup from "./Popups";
import Poll from "./Popups/Poll";
import Questions from "./Questions";
import Roadmap from "./Roadmap";
import Share from "./Share";
import Social from "./Social";
import Team from "./Team";
import Universe from "./Universe";
import Utilities from "./Utilities";

const Home = () => {
  const [value] = useContext(PopupContext);
  const { isPoll, setIsPoll } = useContext(DAppContext);

  return (
    <>
      <Intro />
      <Universe />
      <Share />
      <CharacterSlider />
      {/* <MintInfo /> */}
      <section className="about-section" id="about">
        <Questions />
        <Contribution />
        <Utilities />
        <Roadmap />
      </section>
      <Info />
      <ChapterOne />
      <Collection />
      <Team />
      <Social />
      {value !== "" && <Popup />}
      <Poll open={isPoll} setIsOpen={setIsPoll} />
    </>
  );
};

export default Home;
