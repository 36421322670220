import cn from "classnames";
import { MINT_MODALS_TYPE } from "../../../const";
import styles from "./index.module.scss";

const ModalMintMsgs = ({ type, setIsModal, mint, loading }) => {
  const handleClose = () => {
    setIsModal("");
  };

  return (
    <div className={cn(styles["modal"])}>
      <div className="relative">
        <button onClick={handleClose} className={styles["modal__close"]}>
          <span className="img-container">
            <svg className="icon">
              <use xlinkHref="#close" />
            </svg>
          </span>
        </button>
        {type === MINT_MODALS_TYPE.freemint && (
          <div className={styles["freemint"]}>
            <p>Congratulations on your Freemint spot!</p>
            <h3>
              Your Freemint spot comes with an Allowlist spot too. You may mint
              up to 2 additional Allowlist NFTs for a max of 3 DC NFTs for this
              wallet.
            </h3>
            <div className={styles["freemint__wrap"]}>
              <div className={styles["freemint__item"]}>
                <button
                  disabled={loading}
                  onClick={() => mint(3)}
                  className={styles["freemint__btn"]}
                >
                  ADD + 2 ALLOWLIST NFTs
                </button>
                <span>0.066eth per NFT</span>
              </div>
              <div className={styles["freemint__item"]}>
                <button
                  disabled={loading}
                  onClick={() => mint(2)}
                  className={styles["freemint__btn"]}
                >
                 ADD + 1 ALLOWLIST NFTs
                </button>
                <span>0.066eth per NFT</span>
              </div>
              <div className={styles["freemint__item"]}>
                <button
                  disabled={loading}
                  onClick={() => mint(1)}
                  className={styles["freemint__btn"]}
                >
                  CONTINUE WITH JUST 1 FREEMINT
                </button>
                <span></span>
              </div>
            </div>
          </div>
        )}
        {type === MINT_MODALS_TYPE.not_mint_list && (
          <div className={styles["not-list"]}>
            <h3>
              Unfortunately your wallet is not registed for a Freemint or
              Allowlist. Please come back for the Public Mint on Oct 1st at 6pm
              UTC
            </h3>
            <div>
              <a
                href="https://discord.gg/cSWj6mJTHr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon-dicord">
                  <svg className="icon">
                    <use xlinkHref={`#discord`} />
                  </svg>
                </span>
                Join Discord {"&"} stay up to date {">"}
              </a>
            </div>
          </div>
        )}
        {type === MINT_MODALS_TYPE.public && (
          <div className={styles["public"]}>
            <h3>
              You have (3) DC NFTs in your wallet. You may mint an additional
              (8) NFTs for a total of 11 per wallet.
            </h3>
            <div className={styles["public__wrap"]}>
              <button
                onClick={() => setIsModal("")}
                className={styles["public__back"]}
              >
                {"<"} Go back
              </button>
              <button className={styles["public__btn"]}>
                CONTINUE WITH (8)
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalMintMsgs;
