import React from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.scss";
import { chevron } from "../../../assets";

const CollectionSwiper = ({ images }) => {
  return (
    <>
      <div className="collection-swiper__container">
        <Swiper
          pagination={false}
          modules={[Pagination, Navigation]}
          navigation={{
            nextEl: ".collection-next",
            prevEl: ".collection-prev",
          }}
          className="collection-swiper"
          loop={true}
          spaceBetween={19.89}
          breakpoints={{
            280: {
              slidesPerView: 1,
            },
          }}
        >
          {/* <button className="collection-prev">
                        <span className="img-container">
                            <svg className="icon">
                              <use xlinkHref="#swiperPrev"/>
                            </svg>
                        </span>
                    </button> */}
          {images.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <div className="collection-swiper__img">
                  <div className="img-container">
                    <img src={item.img} alt="slider" />
                  </div>
                </div>
                <div className="collection__desc">
                  <span>{item.name}</span>
                  <button className="collection-next chevron-r"><img alt="Chevron" src={chevron} /></button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default CollectionSwiper;
