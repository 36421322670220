import cn from "classnames";
import { useContext } from "react";
import Countdown from "react-countdown";
import { TIMER_THEME } from "../../const";
import { DAppContext } from "../../context/dapp";
import styles from "./index.module.scss";

const Timer = ({ theme = "default" }) => {
  const { setIsLaunch } = useContext(DAppContext);
  
  const renderer = ({ formatted, completed }) => {
    return (
      <div
        className={cn(styles["timer"], {
          [styles["timer_header"]]: theme === TIMER_THEME.header,
          [styles["timer_default"]]: theme === TIMER_THEME.default,
        })}
      >
        <div className={styles["timer__item"]}>
          <time className={styles["timer__value"]}>{formatted.days}</time>
          <p className={styles["timer__value_type"]}>days</p>
        </div>
        <div className={styles["timer__dots"]}>:</div>
        <div className={styles["timer__item"]}>
          <time className={styles["timer__value"]}>{formatted.hours}</time>
          <p className={styles["timer__value_type"]}>hours</p>
        </div>
        <div className={styles["timer__dots"]}>:</div>
        <div className={styles["timer__item"]}>
          <time className={styles["timer__value"]}>{formatted.minutes}</time>
          <p className={styles["timer__value_type"]}>mins</p>
        </div>
        <div className={styles["timer__dots"]}>:</div>
        <div className={styles["timer__item"]}>
          <time className={styles["timer__value"]}>{formatted.seconds}</time>
          <p className={styles["timer__value_type"]}>secs</p>
        </div>
      </div>
    );
  };
  return (
    <div className={styles["time-wrap"]}>
      <Countdown
        onComplete={() => setIsLaunch(true)}
        renderer={renderer}
        date={new Date("30 September 2022 17:00 UTC")}
      />
    </div>
  );
};

export default Timer;
