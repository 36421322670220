import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({children, forPopup}) => {
    return (
        <>
            <Header active={forPopup}/>
            {children}
            {!forPopup && (
                <Footer/>
            )}
        </>
    )
}

export default Layout