import cn from "classnames";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { cloud1, cloud2 } from "../../assets";
import { inView } from "../../util/helper";
import styles from "./index.module.scss";

const Universe = () => {
  const sectionRef = useRef(null);
  const [distance, setDistance] = useState(0);
  const [isAnimate, setIsAnimateText] = useState({
    text: false,
    thei: false,
    clouds: false,
  });
  const textRef = useRef(null);
  const cloudsRef = useRef(null);
  const isMobile = window.screen.availWidth < 567;

  useEffect(() => {
    const onScroll = () => {
      setDistance(window.scrollY);
      setIsAnimateText({
        text: inView(textRef),
        clouds: inView(cloudsRef),
      });
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <section
      style={{ backgroundPositionY: isMobile ? -distance : -distance - 200 }}
      ref={sectionRef}
      className={styles["universe"]}
    >
      <div className={styles["universe__block"]}>
        <div className="page-container">
          <p
            ref={textRef}
            className={cn(styles["universe__text"], {
              [styles["universe__text_animate"]]: isAnimate.text,
            })}
          >
            In a parallel universe, society is manipulated by a controlling
            entity <br />
            named THEI (The Happy End Inc).
          </p>
        </div>
      </div>
      <div className={styles["universe__cloud-wrap"]} ref={cloudsRef}>
        <img
          className={cn(styles["universe__cloud"], {
            [styles["universe__cloud_animate"]]: isAnimate.clouds,
          })}
          src={cloud1}
          alt="Cloud"
        />
        <img
          className={cn(styles["universe__cloud"], {
            [styles["universe__cloud_animate"]]: isAnimate.clouds,
          })}
          src={cloud2}
          alt="Cloud"
        />
      </div>
    </section>
  );
};

export default Universe;
