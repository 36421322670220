import styles from "./index.module.scss";

const Info = () => {
  return (
    <section className={styles["mint-info"]}>
      <div className="page-container">
        <div className={styles["mint-info__wrap"]}>
          <div className={styles["mint-info__block"]}>
            <div className={styles["mint-info__block-title"]}>
              <h3>
                <strong>
                  General
                  <br /> Info
                </strong>
              </h3>
            </div>
            <p>
              <strong>COLLECTION:</strong> 3333 DC NFTs <br />
              <strong>BLOCKCHAIN:</strong> Bitcoin 
              <br />
              <strong>RARITIES:</strong> 8 Society Groups, 3 Citizen types
              <br />
              <strong>TRAITS:</strong> 500+
            </p>
          </div>
          <div className={styles["mint-info__block"]}>
            <div className={styles["mint-info__block-title"]}>
              <h3>
                <strong>
                  Minting
                  <br /> Info
                </strong>
              </h3>
            </div>
            <p>
              <strong>ALLOWLIST & FREEMINTS:</strong> Airdrop <br />
              <strong>PUBLIC MINT:</strong> Airdrop
              <br />
              <strong>PRICE:</strong> Free Airdrop
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
