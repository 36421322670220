import React, {useContext} from "react";
import './style.scss'
import {PopupContext} from "../../../../context/popup";


const Nav = ({navListData, active, setHidden}) => {
    const [val,setVal] = useContext(PopupContext);

    const navClickHandler = () => {
        active && setVal('');
        setHidden();
        document.body.style.touchAction=null;
        document.body.style.overflow=null;
    }

    return (
        <nav className='nav'>
            <ul className="nav__list">
                {navListData.map((item, index) => {
                    return (
                        <li key={index} className='nav__item'>
                            <a href={`#${item.link}`} className='nav__item-link fz-19' onClick={navClickHandler}>
                                {item.text}
                            </a>
                        </li>
                    )
                })}
                <li className="nav__item">
                    <button className={`nav__item-link fz-19 ${val === 'faq' && `nav__item-link--active`}`} onClick={active ? () => setVal('') : () => setVal('faq')}>
                        FAQ
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;