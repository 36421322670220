import React from "react";
import './style.scss';

const HeaderIcons = ({headerIconsData}) => {
    return (
        <ul className="header-right__icons">
            {headerIconsData.map((item, index) => {
                return (
                    <li key={index} className={`header-right__icons-item header-right__icons-item--${item.xLink}`}>
                        <a target='_blank' rel='noreferrer' href={item.link}
                           className={`header-right__icon-link`}>
                            <span className={`header__right-icon-container header-right__icon-container--${item.xLink}`}>
                                <span className="img-container">
                                    <svg className="icon">
                                      <use xlinkHref={`#${item.xLink}`}/>
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}

export default HeaderIcons