import cn from "classnames";
import React, { useState } from "react";
import "./style.scss";

const QuestionsTabs = ({ questions, getIndex }) => {
  const [tabActive, setTabActive] = useState(0);
  const clickHandler = (index) => {
    getIndex(index);
    setTabActive(index);
  };

  return (
    <ul className="questions__list">
      {questions.map((item, i) => {
        return (
          <li key={i} className="questions__item">
            <button
              type={"button"}
              className="questions__item-button"
              onClick={() => clickHandler(i)}
            >
              <span
                className={`questions__button-text${
                  tabActive === i ? ` questions__button-text--active` : ""
                }`}
              >
                {item.question}
              </span>
              <span
                className={`open-btn ${tabActive === i && "open-btn--active"}`}
              >
                <span className="open-btn__line open-btn__line--top" />
                <span className="open-btn__line open-btn__line--bottom" />
              </span>
            </button>

            <div
              className={cn("questions__answer-dropdown", {
                "questions__answer-dropdown_active": tabActive === i,
              })}
            >
              <p className={`questions__answer fz-19`}>{item.answer}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default QuestionsTabs;
