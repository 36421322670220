import { POLL } from "../data";
import instance from "./axios";

class Poll {
  constructor(url) {
    this.url = url;
  }
  createVote = async (data) => {
    const res = await instance.post(`${this.url}/create/vote`, data);
    return res;
  };
  getVotes = async () => {
    const { data } = await instance.get(`${this.url}/get/poll/${POLL.id}`);
    return data.data?.options;
  };
}

const MAIN_URL = process.env.REACT_APP_POLL_API_URL;
const poll = new Poll(MAIN_URL);

export default poll;
