import { useContext } from "react";
import { borderTop, UtilitiesOurs } from "../../assets";
import { PopupContext } from "../../context/popup";
import { utilities } from "../../data";
import "./index.scss";

const Utilities = () => {
  const [, setVal] = useContext(PopupContext);

  return (
    <div id="utilities" className="utilites">
      <img className="utilites__border-top" src={borderTop} alt="Border" />
      <div className="page-container">
        <div className="utilites__head">
          <h2 className="utilites__title">Utilities</h2>
          <img src={UtilitiesOurs} alt="Ours" />
        </div>
        <div className="utilites__wrap">
          {utilities.map((item) => (
            <div key={item.id} className="card__wrap">
              <div
                className={`card card_${item.color} card_${
                  !item.subtitle && "see"
                }`}
                key={item.id}
              >
                <>
                  <p>{item.subtitle}</p>
                  <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                  <a className="card__btn" onClick={() => setVal("whitepaper")} href={item.anchor}>
                    <svg className="icon">
                      <use xlinkHref="#plus" />
                    </svg>
                    <div className="card__btn-text"> see whitepaper</div>
                  </a>
                </>
              </div>
              <div
                className="card__desc"
                dangerouslySetInnerHTML={{ __html: item.desc }}
              />
              <a onClick={() => setVal("whitepaper")} href={item.anchor}>
                {item.moreText}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Utilities;
