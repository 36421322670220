import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import { schema } from "../../schema";
import Loader from "../loader";
import "./style.scss";
import { EMAILJS } from "../../email";

const Form = () => {
  useEffect(() => {
    emailjs.init(EMAILJS.publicKey);
  }, []);

  const [emailStatus, setEmailStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    msg: "",
  };

  const handleSubmit = async (data, { resetForm }) => {
    setLoading(true);
    try {
      await emailjs.send(EMAILJS.serviceId, EMAILJS.templateId, data);
      resetForm({});
      setEmailStatus(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <form className="footer__form">
      <div className="footer__form-row footer__form-row--two">
        <label className="footer__label">
          <input
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
            placeholder="Full Name"
            type="text"
            className="footer__input fz-19"
          />
        </label>
        <label className="footer__label">
          <input
            onChange={formik.handleChange}
            name="email"
            value={formik.values.email}
            placeholder="Email"
            type="email"
            className="footer__input fz-19"
          />
          <p className="error-text">{formik.errors.email}</p>
        </label>
      </div>
      <div className="footer__form-row">
        <label className="footer__label">
          <textarea
            onChange={formik.handleChange}
            name="msg"
            value={formik.values.msg}
            placeholder="Message"
            className="footer__input footer__input--message fz-19"
          />
        </label>
      </div>
      {emailStatus && (
        <p className="success-text">
          We have received your message and would like to thank you for writing
          to us.
        </p>
      )}
      <div className="footer__form-row">
        <button
          onClick={formik.handleSubmit}
          type="button"
          className="footer__form-button fz-19"
        >
          {loading ? <Loader /> : "Send"}
        </button>
      </div>
    </form>
  );
};

export default Form;
