export const MINT_MODALS_TYPE = {
  not_mint_list: "not_mint_list",
  freemint: "freemint",
  public: "public",
};

export const TIMER_THEME = {
  header: "header",
  default: "default",
};
