import React from "react";
import styles from "./index.module.scss";
import CollectionSwiper from "./CollectionSwiper";
import { collection, collectionDots } from "../../data";
import { chevron, collectionDc, fiftyFive, reveal } from "../../assets";
import { useState } from "react";

const Collection = () => {
  const [activeId, setActiveId] = useState(1);

  const handleChangeId = () => {
    setActiveId((prev) => (prev < 2 ? prev + 1 : 1));
  };

  return (
    <section className={styles["collection"]} id="collection">
      <div className="page-container">
        <div className={styles["collection__wrap"]}>
          <div className={styles["collection__sidebar-l"]}>
            <CollectionSwiper images={collection} />
            <div id="anatomy">
              {activeId === 1 && (
                <div className={styles["slider__text"]}>
                  <div className={styles["slider__text-head"]}>
                    <h2 className={styles["collection__title"]}>
                      3333 - Depressed Citizens
                    </h2>{" "}
                    <button
                      className={styles["slider__btn"]}
                      onClick={handleChangeId}
                    >
                    </button>
                  </div>
                  <p>
                    The Depressed Citizens are artists and a representation of
                    our individuality and our artistic expression through urban
                    fashion. They celebrate diversities and represent 8 society
                    groups.{" "}
                    <strong>
                      {" "}
                      We have split the collection with a link to real life with
                      their likelihood of depression (%) according to USA
                      depression{" "}
                      <a
                        href="https://mhanational.org/mental-health-data-2020"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        statistics.
                      </a>
                    </strong>
                  </p>
                  <ul className={styles["slider__list"]}>
                    <li>1687 - Caucasians</li>
                    <li>572 - Asian or Pacific Islanders</li>
                    <li>422 - Hispanic or Latinos</li>
                    <li>293 - African Decendants</li>
                    <li>169 - LGBTQ</li>
                    <li>148 - Uniques</li>
                    <li>42 - Native Indigenous</li>
                    <li>7 - Revolutionary Figures</li>
                  </ul>
                </div>
              )}
              
             
        
            </div>
          </div>
          <div>
            <h2 className={styles["collection__title"]}>
              the Collection of 3333
            </h2>
            <div className={styles["slider__slide"]}>
                 <img src={fiftyFive} alt="55" />

              {activeId === 1 && (
                <div>
                  <img
                    className={styles["slider__img"]}
                    src={collectionDc}
                    alt="Dc"
                  />
                  <div>
                    {collectionDots?.dc?.map((dot) => (
                      <div className={styles["slider__info-item"]} key={dot.id}>
                        <div className={styles["slider__info-dot"]} />
                        <div className={styles["slider__info-desc"]}>
                          <h3>{dot.name}</h3>
                          <p>{dot.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
